.remote-job-loader .preloader {
  position: fixed;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  z-index: 9999999999;
  background-color: #00000090;
  background-size: 50%;
  display: flex;
  align-items: center;
  text-align: center;
  margin: 0 auto;
  text-align: center;
  backdrop-filter: blur(8px);
}
.remote-job-loader .preloader .ring {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 150px;
  height: 150px;
  background: #fff;
  border: 3px solid #05a105;
  border-radius: 50%;
  text-align: center;
  line-height: 150px;
  font-family: sans-serif;
  font-size: 20px;
  color: #05a105;
  letter-spacing: 4px;
  text-transform: uppercase;
  text-shadow: 0 0 10px #05a105;
  box-shadow: 0 0 20px rgba(0,0,0,.5);
}
.remote-job-loader .preloader .ring:before {
  content: '';
  position: absolute;
  top: -3px;
  left: -3px;
  width: 100%;
  height: 100%;
  border: 3px solid transparent;
  border-top: 3px solid #05a105;
  border-right: 3px solid #05a105;
  border-radius: 50%;
  animation: animateC 2s linear infinite;
}
.remote-job-loader .preloader .ring .img-box {
  padding: 12px;
  position: relative;
  top: -15px;
}
.remote-job-loader .preloader .ring span
{
  display:block;
  position:absolute;
  top:calc(50% - 2px);
  left:50%;
  width:50%;
  height:4px;
  background:transparent;
  transform-origin:left;
  animation:animate 2s linear infinite;
}
.remote-job-loader .preloader .ring span:before {
  content: '';
  position: absolute;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background: #05a105;
  top: -6px;
  right: -8px;
  box-shadow: 0 0 20px #05a105;
}
@keyframes animateC
{
  0%
  {
    transform:rotate(0deg);
  }
  100%
  {
    transform:rotate(360deg);
  }
}
@keyframes animate
{
  0%
  {
    transform:rotate(45deg);
  }
  100%
  {
    transform:rotate(405deg);
  }
}