.epic-fail-page {
  overflow: hidden;
}

.MuiFormHelperText-root {
  color: white !important;
  font-size: 11px !important;
}

a {
  text-decoration: none;
  cursor: pointer;
  color: #ff6c6c;
}

a:hover,
a:focus,
a:visited {
  text-decoration: none !important;
  outline: none;
  color: rgba(75, 34, 11, 0.8);
}

.btn-style-one {
  position: relative;
  background-color: transparent;
  color: #fff !important;
  display: inline-block;
  font-size: 25px;
  font-weight: 600;
  border-radius: 0;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  text-align: center;
  line-height: 36px;
  text-transform: uppercase;
  font-weight: 700;
  background-color: transparent;
  border-radius: 15px;
  border: 8px solid #9468c9;
  transition: all 0.3s ease-in-out;
  font-family: 'FONTSPRING DEMO Universo';
}

.btn-style-one span {
  background-color: #0d0934;
  border: 2px solid #000;
  width: 270px;
  display: block;
  height: 54px;
  line-height: 48px;
  border-radius: 8px;
  transition: all 0.3s ease-in-out;
  text-transform: uppercase;
  font-family: 'FONTSPRING DEMO Universo';
  font-weight: 900;
  text-shadow: 3px 3px 0 #034c4f;
}

.btn-style-one:hover {
  border: 8px solid #0d0934;
}

.btn-style-one:hover span {
  background-color: #9468c9;
}

.btn-style-two {
  position: relative;
  background-color: #b6673c;
  color: #fff !important;
  display: inline-block;
  padding: 10px 25px;
  font-size: 18px;
  font-weight: 600;
  border-radius: 35px;
  transition: all 0.3s ease-in-out;
}

.btn-style-two:hover {
  background-color: #df956d;
}



.epic-fail-page .sec-title {
  position: relative;
  text-align: left;
  margin-bottom: 100px;
}

.epic-fail-page .sec-title h2 {
  font-size: 100px;
  color: #fff;
  text-transform: uppercase;
  font-family: 'FONTSPRING DEMO Universo';
  font-weight: 900;
  text-shadow: 0px -4px #ed3832;
}

.epic-fail-page .sec-title h2 span i {
  font-style: normal;
  font-family: 'Arial';
  font-weight: 900;
}

/* Header Main Css Start*/

.epic-fail-page .header {
  position: relative;
  width: 100%;

  background-size: cover;
  background-position: bottom center;
  background-repeat: no-repeat;
}

.epic-fail-page .landing-marquee .marquee-container a i {
  color: #F2D4C4;
}

.epic-fail-page .landing-marquee {
  background: #4B220B;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 0;
}

.epic-fail-page .banner-section {
  position: relative;
  background-size: 100%;
  background-position: bottom right;
  background-repeat: no-repeat;
  padding: 0;
  height: 100vh;
  overflow: hidden;
}
.epic-fail-page .banner-section .bottom-arrow-area {
  position: absolute;
  bottom: 256px;
  left: 50%;
  transform: translateX(-50%);
}
.epic-fail-page .banner-section .bottom-arrow-area button {
  background-color: transparent;
  border: none;
}
.epic-fail-page .banner-section .video-bg {
  margin-top: 0;
  position: relative;
  top: -108px;
}
.epic-fail-page .banner-bg {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

.slider-container.marque-area .slick-list p {
  transform: rotate(-8deg);
  font-size: 17px;
  color: #fff;
  text-transform: uppercase;
  font-family: 'Crazy Guy';
}

.epic-fail-page .banner-section .btn-area {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}


.epic-section {
  position: relative;
}

.epic-section .video-bg {
  position: relative;
  top: -70px;
}

.epic-section .video-bg img {
  width: 100%;
}

.epic-section .video-bg .text-box {
  position: absolute;
  left: 50%;
  bottom: 30%;
  transform: translate(-50%, 0);
  z-index: 22;
}

.epic-section .video-bg .text-box h2 {
  color: #fff;
  font-size: 30px;
  font-family: 'FONTSPRING DEMO Universo';
  font-weight: 900;
}

.lazy-label {
  margin-top: -96px;
}

.epic-fail-page .banner-section .banner-image {
  margin-bottom: -84px;
  text-align: center;
  margin-right: -151px;
  position: relative;
}

.epic-fail-page .banner-section .col-lg-5 {
  padding: 0;
}

.epic-fail-page .banner-section .banner-image .z-img {
  position: absolute;
  top: 89px;
  width: 186px;
  left: 245px;
  animation-name: fadeIn;
  animation-duration: 2s;
  animation-iteration-count: infinite;
}

.epic-fail-page .banner-section .banner-image .lazy-img {
  position: absolute;
  top: 68px;
  left: 88px;
  width: 200px;
  animation: zoom-in-zoom-out-2 6s ease infinite;
}

.epic-fail-page .banner-section .banner-text {
  position: relative;
  width: 40%;
}
.epic-fail-page .banner-section .dog-bg {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  transition: all 1.3s ease-in-out;
  opacity: 0;
  visibility: hidden;
}
.epic-fail-page .banner-section:hover .dog-bg{
  bottom: 211px;
  opacity: 1;
  visibility: visible;
}
.epic-fail-page .banner-section .banner-left-area {
  position: relative;
  text-align: center;
  width: 100%;
}
.epic-fail-page .banner-section .right-dog {
  position: absolute;
  animation-duration: 5s;
  animation-iteration-count: infinite;
  transform-origin: bottom;
  animation-name: bounce-1;
  animation-timing-function: linear;
  top: 173px;
  right: -184px;
}
.epic-fail-page .banner-section .right-dog img {
  width: 400px;
}
.epic-fail-page .banner-section .banner-text h1 {
  position: relative;
  overflow: hidden;
}
.epic-fail-page .banner-section .banner-text .banner-img {
  position: relative;
  margin-top: -131px;
}

.epic-fail-page .banner-section .banner-text .banner-img img {
  width: 367px;
  animation: spin 8s ease infinite;
}

.epic-fail-page .banner-section .stone-bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}

.epic-fail-page .banner-section .stone-bg img {
  width: 100%;
}

.epic-fail-page .banner-section .grass-bg {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
}
.epic-fail-page .coins-sec .ball-img {
  position: absolute;
  left: 37%;
  animation-duration: 8s;
    animation-iteration-count: infinite;
    transform-origin: bottom;
    animation-name: bounce-1;
    animation-timing-function: linear;
}

.epic-fail-page .banner-section .grass-bg img {
  width: 100%;
}
.epic-fail-page .banner-section .sun-img {
  position: absolute;
  right: 400px;
  top: 0;
  animation-name: fadeInBottom ;
  animation-duration: 5s;
    animation-fill-mode: both;
}
.epic-fail-page .banner-section .sun-img img{
  animation: zoom-in-zoom-out-2 6s ease infinite;
}
.cloud-img {
  position: absolute;
  top: 0;
  -webkit-animation: animateCloud 35s linear infinite;
  -moz-animation: animateCloud 35s linear infinite;
  animation: animateCloud 35s linear infinite;

  -webkit-transform: scale(0.75);
  -moz-transform: scale(0.75);
  transform: scale(0.75);
}

.epic-fail-page .banner-section .animation-coin-1 {
  position: absolute;
  top: 43px;
  left: 50px;
  opacity: 0.5;
  width: 92px;
  animation-duration: 10s;
  animation-iteration-count: infinite;
  transform-origin: bottom;
  animation-name: bounce-1;
}
.epic-fail-page .banner-section .animation-coin-2 {
  position: absolute;
  left: 50%;
  opacity: 0.4;
  width: 100px;
  animation: spin 6s ease infinite;
}
.epic-fail-page .banner-section .animation-coin-3 {
  position: absolute;
  right: 26px;
  bottom: 25px;
  width: 100px;
  opacity: 0.3;
  animation: zoom-in-zoom-out-2 6s ease infinite;
}
.epic-fail-page .banner-section .animation-coin-4 {
  position: absolute;
  bottom: 129px;
  left: 250px;
  width: 100px;
  opacity: 0.3;
  animation-name: fadeInBottom ;
  animation-duration: 8s;
    animation-fill-mode: both;
    animation-iteration-count: infinite;
}
@-webkit-keyframes animateCloud {
  0% {
    margin-left: -1000px;
  }

  100% {
    margin-left: 100%;
  }
}

@-moz-keyframes animateCloud {
  0% {
    margin-left: -1000px;
  }

  100% {
    margin-left: 100%;
  }
}

@keyframes animateCloud {
  0% {
    margin-left: -1000px;
  }

  100% {
    margin-left: 100%;
  }
}




@-webkit-keyframes animateCloud2 {
  0% {
    margin-left: 200px;
  }

  100% {
    margin-left: -200px;
  }
}

@-moz-keyframes animateCloud2 {
  0% {
    margin-left: 200px;
  }

  100% {
    margin-left: -200px;
  }
}

@keyframes animateCloud2 {
  0% {
    margin-left: 200px;
  }

  100% {
    margin-left: -200px;
  }
}



@keyframes fadeInBottom {
  0% {
      opacity: 0;
      transform: translateY(100%);
  }
  100% { opacity: 1 }
}
@keyframes text {
  0% {
    margin-bottom: 10px;
  }

  30% {
    letter-spacing: 25px;
    margin-bottom: 10px;
  }

  85% {
    letter-spacing: 8px;
    margin-bottom: 10px;
  }

  100% {
    margin-bottom: 10px;
  }
}


.epic-fail-page .banner-section .banner-text .buy-area {
  position: relative;
  transition: all 0.3s ease-in-out;
  background-color: #9b5606;
  color: #fff;
  font-size: 20px;
  padding: 10px 20px;
  border-radius: 8px;
  font-family: 'BROTHER';
  margin-top: 21px;
  display: inline-block;
  position: relative;
    overflow: hidden;
}
.epic-fail-page .banner-section .banner-text .buy-area:before {
  content: '';
    position: absolute;
    width: 100px;
    height: 100%;
    background-image: linear-gradient(
        120deg,
        rgba(255,255,255, 0) 30%,
        rgba(255,255,255, .8),
        rgba(255,255,255, 0) 70%
    );
    top: 0;
    left: -100px;
    animation: shine2 3s infinite linear; /* Animation */
}
@keyframes shine2 {
  0% {left: -100px}
  20% {left: 100%}
  100% {left: 100%}
}
.epic-fail-page .banner-section .banner-text .buy-area:hover{
  background-color: #c1d100;
}

.epic-fail-page .banner-section .banner-image img {
  width: 100%;
}

.epic-fail-page .banner-section .banner-text p {
  font-size: 27px;
  font-weight: 400;
  color: #fff;
  line-height: 29px;
  margin: 0;
  font-family: 'BROTHER';
}
.epic-fail-page .banner-section .banner-video video {
  width: 100%;
  height: 1000px;
  object-fit: fill;
}

.epic-fail-page .banner-section .banner-video {
  position: absolute;
  width: 100%;
  left: 0;
  bottom: 0;
}
.epic-fail-page .banner-section .banner-video img {
  width: 100%;
}
.epic-fail-page .banner-section .banner-text .btn-area {
  position: relative;
}

.epic-fail-page .banner-section .banner-text .btn-area ul {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin-top: 350px;
}

.epic-fail-page .banner-section .banner-text .btn-area ul li {
  margin-right: 21px;
  margin-bottom: 23px;
}

.epic-fail-page .banner-section .banner-text .btn-area ul li .whitepaper,
.epic-fail-page .banner-section .banner-text .btn-area ul li .tenshi:hover {
  background-color: #F2D4C4;
  color: #4B220B !important;
}

.epic-fail-page .banner-section .banner-text .btn-area ul li .tenshi,
.epic-fail-page .banner-section .banner-text .btn-area ul li .whitepaper:hover {
  background-color: #4B220B;
  color: #F2D4C4 !important;
}

@keyframes shine {
  to {
    background-position: 200% center;
  }
}


.epic-fail-page .about-sec .heading-area-left .text-box p {
  font-size: 40px;
  margin-bottom: 0;
  line-height: 58px;
  color: #fff;
  font-family: 'FONTSPRING DEMO Universo';
}

.epic-fail-page .about-sec .heading-area-right {
  position: relative;
}

.epic-fail-page .about-sec .heading-area-right .heading-box {
  position: relative;
  text-align: left;
  zoom: 1;
  margin-left: -251px;
  margin-bottom: -59px;
  margin-right: 0;
}

.epic-fail-page .about-sec .heading-area-right .heading-box img {
  width: 100%;
}

.epic-fail-page .about-sec .heading-area-right .ring-img {
  zoom: 1;
  left: -157px;
  bottom: 227px;
  position: absolute;
  animation: spin 6s ease infinite;
}

.epic-fail-page .about-sec .text-block .text-box {
  margin-top: 100px;
}

.epic-fail-page .about-sec .text-block .text-box h4 {
  font-size: 45px;
  font-weight: 600;
  margin-bottom: 15px;
  color: #4B220B;
}

.epic-fail-page .about-sec .text-block .text-box p {
  line-height: 30px;
  font-size: 16px;
  margin-bottom: 20px;
  color: #4B220B;
}

.epic-fail-page .toknomics-sec{
  position: relative;
  padding: 104px 0 222px;
  background-position: top center;
  background-size: 100% 100%;
}
.epic-fail-page .toknomics-sec .toknomics-area-boxes {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
}
.epic-fail-page .toknomics-sec .toknomics-box {
  position: relative;
  background-color: #ffffff17;
  border-radius: 8px;
  padding: 20px;
  text-align: center;
  transition: all 0.3s ease-in-out;
}
.epic-fail-page .toknomics-sec .toknomics-box:hover{
  box-shadow: 5px 10px #ffffff20;
}
.epic-fail-page .toknomics-sec .toknomics-box .text-box {
  position: relative;
  min-height: 121px;
  transition: all 0.3s ease-in-out;
}

.epic-fail-page .toknomics-sec .toknomics-box .text-box h4 {
  position: relative;
  color: #fff;
  font-size: 22px;
  font-family: 'BROTHER';
  letter-spacing: 1.5px;
}

.epic-fail-page .toknomics-sec .toknomics-box .text-box p {
  color: #000;
  font-family: 'FONTSPRING DEMO Universo';
  font-size: 16px;
  margin-bottom: 0;
}

.epic-fail-page .toknomics-sec .toknomics-box .text-box .icon {
  position: absolute;
  bottom: 0;
  left: 50%;
  width: auto;
  margin: 0 auto;
  transform: translateX(-50%);
  background-color: transparent;
  border: none;
}
.epic-fail-page .toknomics-sec .toknomics-box .text-box .icon img {
  width: 39px;
  animation: zoom-in-zoom-out-2 6s ease infinite;
}


.fail-sec {
  position: relative;
  padding-bottom: 0;
}

.fail-sec .sec-title h2 {
  text-shadow: none;
  font-weight: 300;
  font-size: 37px;
}

.fail-sec .sec-title h2 span {
  font-family: arial;
}

.fail-sec .animation-box {
  position: relative;
  margin-top: -257px;
}
.fail-sec .animation-box .train-img {
  position: absolute;
  z-index: 1;
  top: 360px;
}
.fail-sec .animation-box .train-img img{
  animation: mymove 5s infinite;
  position: relative;
}
@keyframes mymove {
  0%   {left: 582px;}
  25%  {left: 782px;}
  75%  {left: 382px}
  100% {left: 582px;}
}

.fail-sec .animation-box .chartor-img {
  position: absolute;
  bottom: 28px;
  z-index: 2;
  left: 28%;
  animation-duration: 10s;
  animation-iteration-count: infinite;
  transform-origin: bottom;
  animation-name: bounce-1;
}

.fail-sec .animation-box .chartor-img-2 {
  position: absolute;
  bottom: 81px;
  z-index: 3;
  right: 11%;
}
.fail-sec .animation-box .main-img {
  position: relative;
  z-index: 1;
}

.epic-fail-page .toknomics-sec .toknomics-box .img-box .text-box textarea {
  opacity: 0;
  visibility: hidden;
}
.epic-fail-page .toknomics-sec .table-img-area {
  position: relative;
  z-index: 3;
  margin-top: -37px;
  margin-left: 14px;
}
.epic-fail-page .toknomics-sec .table-img-area .table-img {
  width: 273px;
}
.epic-fail-page .toknomics-sec .table-img-area .dog-img {
  width: 116px;
  position: absolute;
  top: -26px;
  left: -161px;
  opacity: 0;
  transition: all 1.5s ease-in-out;
}
.epic-fail-page .toknomics-sec .table-img-area:hover .dog-img {
  width: 116px;
  position: absolute;
  top: -26px;
  left: 61px;
  opacity: 1;
}

.stones-img-area {
  position: absolute;
  top: 522px;
  z-index: 1;
}

.stones-img-area .dog-img {
  position: absolute;
  top: 15px;
  left: 50%;
  width: 135px;
  transform: translateX(-50%);
  opacity: 0;
  visibility: hidden;
  transition: all 1.5s ease-in-out;
}
.stones-img-area:hover .dog-img {
  opacity: 1;
  visibility: visible;
  top: -55px;
}

.stones-img-area img.table-img {
  position: relative;
  z-index: 2;
}



@keyframes sunpulse {
	from {
		box-shadow: 0 0 100px #ff0, 0 0 100px #ff0;
	}
	to {
		box-shadow: 0 0 25px #ff0, 0 0 75px #ff0;
	}
}
.epic-fail-page .toknomics-sec .right-img-area {
  position: absolute;
  right: 30px;
  bottom: 12px;
  z-index: 1;
  animation-duration: 10s;
  animation-iteration-count: infinite;
  transform-origin: bottom;
  animation-name: bounce-1;
}

.epic-fail-page .toknomics-sec .right-img-area .dog-img {
  width: 300px;
}
.epic-fail-page .toknomics-sec .toknomics-box .img-box .text-box h4 {
  font-family: 'BROTHER';
  font-size: 20px;
  color: #451a00;
  margin-bottom: 4px;
  letter-spacing: 2px;
}
.epic-fail-page .toknomics-sec .car-animation {
  position: absolute;
  bottom: -49px;
  width: 100%;
  z-index: 9;
}
.epic-fail-page .toknomics-sec .toknomics-box .img-box .text-box p {
  font-size: 16px;
  color: #fff;
  font-family: 'BRIGHTSIGHT02';
  letter-spacing: 4px;
}
.epic-fail-page .toknomics-sec .toknomics-box .img-box .text-box .icon {
  position: absolute;
  bottom: 0;
  text-align: center;
  left: 50%;
  transform: translateX(-50%);
  background-color: transparent;
  border: none;
}
.epic-fail-page .toknomics-sec .toknomics-box .img-box .text-box .icon img{
  position: relative;
  width: 36px;
  animation: zoom-in-zoom-out-2 6s ease infinite;
}
.epic-fail-page .toknomics-sec .sec-title {
  padding-left: 0;
  text-align: center;
}
.epic-fail-page .toknomics-sec .bottom-img {
  position: relative;
  text-align: center;
  margin-top: 100px;
}
.epic-fail-page .toknomics-sec .slider-container.marque-area {
  margin-top: -46px;
  margin-bottom: -38px;
}

.epic-section .epic-fail-nav {
  bottom: 150px;  
}


.epic-fail-page .join-sec {
  position: relative;
  padding: 100px 0 80px;
  background-position: top center;
  background-size: 100% 100%;
  
}
.epic-fail-page .join-sec .heading-memes-area-left .left-img {
  position: relative;
}

.epic-fail-page .join-sec .heading-memes-area-left .left-img img {
  width: 500px;
}
.epic-fail-page .join-sec .heading-memes-area-left .tree-img {
  position: relative;
  margin-left: -49px;
  z-index: 1;
}
.epic-fail-page .join-sec .heading-memes-area-left .tree-img img{
  width: 407px;
}
.epic-fail-page .join-sec .heading-memes-area-left .tree-img .dof-img {
  position: absolute;
  left: 392px;
  bottom: 0;
  animation-duration: 6s;
  animation-iteration-count: infinite;
  transform-origin: bottom;
  animation-name: bounce-1;
  animation-timing-function: linear;
}
.epic-fail-page .coins-sec .tree-left-area {
  position: relative;
}
.epic-fail-page .join-sec .bottom-join {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
}
 
.epic-fail-page .join-sec .bottom-join img {
  width: 100%;
}
.epic-fail-page .coins-sec .tree-left-area .chartor-area {
  position: relative;
  margin-top: -286px;
  -webkit-animation: animateCloud2 35s linear infinite;
  -moz-animation: animateCloud2 35s linear infinite;
  animation: animateCloud2 35s linear infinite;
}

.epic-fail-page .coins-sec .baby-img {
  position: absolute;
  top: 33%;
  right: 35%;
  animation-duration: 5s;
  animation-iteration-count: infinite;
  transform-origin: bottom;
  animation-name: bounce-1;
  animation-timing-function: linear;
}

.epic-fail-page .coins-sec .dog-right-img {
  position: absolute;
  bottom: 126px;
  right: -2px;
}

.epic-fail-page .coins-sec .dog-right-img img {
  width: 600px;
}

.epic-fail-page .join-sec .sec-title {
  margin-bottom: 0;
  margin-top: 0;
  text-align: center;
}

.epic-fail-page .join-sec .sec-title h1 {
  font-size: 150px;
  color: #fff;
  text-transform: uppercase;
  font-family: 'FONTSPRING DEMO Universo';
  font-weight: 900;
  text-shadow: 0px -4px #ed3832;
  margin-bottom: 70px;
}

.epic-fail-page .join-sec .sec-title h2 span {
  font-size: 70px;
}

@keyframes anim {
  50%, 80% {
    width: 100%;
  }
}

.epic-fail-page .join-sec .sec-title ul {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 54px;
}

.epic-fail-page .join-sec .sec-title ul li {
  margin: 0 20px;
}

.epic-fail-page .join-sec .sec-title ul li a {
  width: 80px;
  height: 80px;
  background-color: #000;
  border-radius: 50%;
  border: 2px solid #000;
  display: block;
  text-align: center;
  transition: all 0.3s ease-in-out;
  animation-duration: 5s;
  animation-iteration-count: infinite;
  transform-origin: bottom;
  animation-name: bounce-1;
  animation-timing-function: linear;
}

.epic-fail-page .join-sec .sec-title ul li a img {
  width: 41px;
  display: block;
  margin: 10px auto 0;
  transition: all 0.3s ease-in-out;
}

.epic-fail-page .join-sec .sec-title ul li a:hover img{
  filter: invert(1);
}
.epic-fail-page .join-sec .sec-title ul li a:hover, 
.epic-fail-page .join-sec .sec-title ul li a.active{
  color: #451a00;
  background-color: #ed3832;
  border: 2px solid #ed3832;
}
.epic-fail-page .join-sec .sec-title h2 span {
  color: #fff;
}
@keyframes zoom-in-zoom-out-2 {
  0% {
    transform: scale(1, 1);
  }

  50% {
    transform: scale(1.2, 1.2);
  }

  100% {
    transform: scale(1, 1);
  }
}


@keyframes bounce-1 {
  0% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(-40px);
  }

  100% {
    transform: translateY(0);
  }
}

@keyframes bounce-2 {
  0% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(-80px);
  }

  100% {
    transform: translateY(0);
  }
}



@keyframes zoom-in-zoom-out {
  0% {
    transform: scale(1, 1);
  }

  50% {
    transform: scale(1.5, 1.5);
  }

  100% {
    transform: scale(1, 1);
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}


@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}


@keyframes zoom-in-zoom-out {
  0% {
    transform: scale(1, 1);
  }

  50% {
    transform: scale(1.5, 1.5);
  }

  100% {
    transform: scale(1, 1);
  }
}

.epic-fail-page .safe-sec .bootm-img {
  position: absolute;
  bottom: -8px;
  left: 50%;
  transform: translateX(-50%);
  animation-name: fadeIn;
  animation-duration: 2s;
  animation-iteration-count: infinite;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 0.5;
  }

  100% {
    opacity: 1;
  }
}



.epic-fail-page .commenty-sec {
  position: relative;
  padding: 100px 0;

}

.epic-fail-page .commenty-sec .text-block .text-box {
  margin-top: 100px;
}

.epic-fail-page .commenty-sec .text-block .text-box h4 {
  font-size: 45px;
  font-weight: 600;
  margin-bottom: 15px;
  color: #4B220B;
}

.epic-fail-page .commenty-sec .text-block .text-box p {
  line-height: 30px;
  font-size: 16px;
  margin-bottom: 20px;
  color: #4B220B;
}

@keyframes zoom-in-zoom-out {
  0% {
    transform: scale(1, 1);
  }

  50% {
    transform: scale(1.1, 1.1);
  }

  100% {
    transform: scale(1, 1);
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}


@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes zoom-in-zoom-out {
  0% {
    transform: scale(1, 1);
  }

  50% {
    transform: scale(1.5, 1.5);
  }

  100% {
    transform: scale(1, 1);
  }
}


@media(max-width: 1700px) {
  .epic-fail-page .banner-section{
    padding-bottom: 50px;
  }
  .fail-sec .animation-box .train-img{
    top: 291px !important;
  }
  .fail-sec .animation-box .chartor-img-2{
    bottom: 66px;
    z-index: 3;
  }
  .epic-fail-page .join-sec .sec-title h1{
    font-size: 120px;
  }
  .epic-section .epic-fail-nav {
    bottom: 100px;
  }
}

@media(max-width: 1610px) {
  .epic-fail-page .fail-sec .sec-title h2 {
    font-size: 35px;
  }
  .epic-section .epic-fail-nav {
    bottom: 171px;
  }
  .epic-section .video-bg {
    position: relative;
    top: -166px;
  }
}

@media(max-width: 1500px) {
  .fail-sec .animation-box .train-img {
    top: 219px !important;
  }
  .epic-fail-page .join-sec .sec-title h1 {
    font-size: 98px;
  }
  .epic-fail-page .sec-title h2{
    font-size: 75px;
  }
  .epic-fail-page .join-sec .sec-title h2 span {
    font-size: 56px;
  }
}

@media(max-width: 1440px) {
  .epic-fail-page .banner-section .video-bg{
    top: -44px;
  }
  .epic-fail-page .banner-section .bottom-arrow-area{
    bottom: 56px;
  }
}

@media(max-width: 1380px) {
  .epic-fail-page .banner-section .video-bg {
    top: -126px;
  }
  .epic-fail-page .banner-section .bottom-arrow-area{
    bottom: 219px;
    z-index: 99;
  }
  .epic-fail-page .banner-section .bottom-arrow-area img {
    width: 100px;
  }
}

@media(max-width: 1300px) {
  
}

@media(max-width: 1199px) {
  .epic-fail-page .toknomics-sec {
    position: relative;
    padding: 0 0 200px;
    background-position: top center;
    background-size: 100% 100%;
  }
  .epic-fail-page .sec-title h2 {
    font-size: 54px;
  }
  .fail-sec .animation-box .train-img {
    top: 159px !important;
  }
  .fail-sec .animation-box .chartor-img-2 {
    bottom: 49px;
    width: 250px;
  }
  .fail-sec .animation-box .chartor-img{
    width: 150px;
  }
}
@media(max-width: 992px) {
  .epic-fail-page .fail-sec .sec-title h2 {
    font-size: 23px;
  }
  .epic-fail-page .banner-section .bottom-arrow-area {
    bottom: 0;
    z-index: 99;
  }
  .epic-fail-page .banner-section .video-bg {
    top: 0;
  }
  .epic-fail-page .banner-section {
    padding-bottom: 0;
    padding-top: 150px;
  }
  .epic-section .video-bg {
    position: relative;
    top: 0;
  }
  .epic-section .epic-fail-nav {
    bottom: 0;
  }
  .epic-fail-page .toknomics-sec {
    padding: 58px 0 100px;
  }
  .epic-fail-page .toknomics-sec .toknomics-box{
    margin-bottom: 30px;
  }
  .fail-sec .animation-box .train-img {
    top: 140px !important;
    width: 61%;
  }
  .fail-sec .animation-box .chartor-img {
    width: 100px;
  }
  .fail-sec .animation-box {
    position: relative;
    margin-top: -157px;
  }
  .epic-fail-page .join-sec .order-area {
    order: 2;
  }
  .epic-fail-page .join-sec .heading-memes-area-left .left-img {
    position: relative;
    text-align: center;
  }
  .epic-fail-page .join-sec .sec-title h1 {
    font-size: 75px;
    margin-bottom: 20px;
  }
}
@media(max-width: 785px) {
  .epic-section .video-bg .text-box h2 {
    font-size: 17px;
  }
}
@media(max-width: 710px){
  .fail-sec .animation-box .chartor-img {
    width: 70px;
  }
  .fail-sec .animation-box .train-img {
    top: 119px !important;
    width: 54%;
  }
}
@media(max-width: 610px){
  .epic-fail-page .banner-section .bottom-arrow-area {
    bottom: -113px;
  }
  .epic-fail-page .sec-title h2 {
    font-size: 45px;
  }
  .epic-fail-page .sec-title{
    margin-bottom: 50px;
  }
  @keyframes mymove {
    0%   {left: 100px;}
    25%  {left: 252px;}
    75%  {left: 150px}
    100% {left: 100px;}
  }
  .fail-sec .animation-box {
    position: relative;
    margin-top: -84px;
  }
  .fail-sec .animation-box .chartor-img {
    width: 47px;
  }
  .fail-sec .animation-box .chartor-img-2 {
    bottom: 23px;
    width: 189px;
  }
}
@media(max-width: 500px) {
  .epic-section .video-bg .text-box h2 {
    font-size: 9px;
  }
  .fail-sec .animation-box .train-img {
    top: 83px !important;
    width: 54%;
  }
  .epic-fail-page .fail-sec .sec-title h2 {
    font-size: 15px;
  }
  .epic-fail-page .join-sec .sec-title h1 {
    font-size: 52px;
    margin-bottom: 20px;
  }
  .epic-fail-page .join-sec .sec-title h2 span {
    font-size: 33px;
  }
}

@media(max-width: 420px) {
  .fail-sec .animation-box .train-img {
    top: 61px !important;
    width: 54%;
  }
  .fail-sec .animation-box .chartor-img{
    bottom: 2px;
    width: 32px;
  }
  .fail-sec .animation-box .chartor-img-2 {
    bottom: 14px;
    width: 104px;
  }
}
@media(max-width: 360px) {
  .epic-fail-page .sec-title h2 {
    font-size: 35px;
  }
}